<template>
  <div v-if="listAddress && listAddress.length">
     <ui-checkbox-group
    class="form"
    @on-change="changeBoxGroup"
    v-model:value.sync="checked1"
  >
    <ui-checkbox
      size="large"
      class="item-wapper"
      v-for="(item, index) in listAddress"
      circle
      :key="index"
      :label="item.id"
    >
      <div class="item address-item">
        <div class="left" style="margin-right: 20px">
          {{ item.receiver }}
        </div>
        <div class="right">{{ item.contactTel }}</div>
      </div>
      <div class="item">
        <div class="left tip address-detail">
          {{ item.province }}&nbsp;{{ item.city }}&nbsp;{{
            item.country
          }}&nbsp;{{ item.receiveAddress }}
        </div>
      </div>

      <div class="default" v-if="item.isDefault">默认</div>
    </ui-checkbox>
  </ui-checkbox-group>

  <div class="empty" v-if="listAddress && !listAddress.length">
    <img
      src="@/assets/img/icon36.png"
      class="focus_img"
    />
    <div>收货地址空空如也~</div>
  </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
  watch,
  onMounted,
} from "vue";
import UiCheckbox from "@/components/UI/Checkbox";
import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
export default defineComponent({
  name: "AddressList",
  props: {
    listAddress: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => {
        return [];
      },
    },
  },
  components: {
    UiCheckbox,
    UiCheckboxGroup,
  },
  setup(props, ctx) {
    const checked1 = ref<string[]>([]);
    const nativeCheck = computed(() => {
      return props.modelValue;
    });
    function setCheckValue() {
      checked1.value = nativeCheck.value;
    }
    watch(nativeCheck, () => {
      setCheckValue();
    });
    const changeBoxGroup = (val: any) => {
      if (checked1.value.length > 1) {
        checked1.value.shift();
      }
      ctx.emit("update:modelValue", val);
    };
    onMounted(() => {
      setCheckValue();
    });

    return {
      checked1,
      changeBoxGroup,
    };
  },
});
</script>

<style lang="scss" scoped>
.form {
  width: 375px;
}

.item-wapper {
  width: 375px;

  height: auto;
  box-sizing: border-box;
  border-bottom: 7.5px solid #f4f4f4;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  :deep(.zm-checkbox__label) {
    display: block;
  }
}

.form .item-content {
  background: url(http://www.gbw114.com//static/m/images/check_box.png) no-repeat
    10px center;
  background-size: 20px;
  color: #444444;
  font-size: 15px;
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px;
  overflow: hidden;
  box-sizing: border-box;
  padding-left: 42.487px;
}

.form .active {
  background: url(http://www.gbw114.com//static/m/images/check_box_red.svg)
    no-repeat 10px center;
  background-size: 20px;
}

.item-content {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: translateX(130px);
  transform: translateX(130px);

  width: 100%;
  margin-right: 0;
  margin-left: -130px;
  padding: 0 15px;
  box-sizing: border-box;
}

.item-operation {
  width: 130px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  -webkit-transform: translateX(130px);
  transform: translateX(130px);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.edit {
  width: 65px;
  float: left;
  height: 90px;
  background: #b9b9b9;
  color: #fff;
  font-size: 12px;
  line-height: 90px;
  text-align: center;
}

.del {
  width: 65px;
  float: right;
  height: 90px;
  background: #b9135a;
  color: #fff;
  font-size: 12px;
  line-height: 90px;
  text-align: center;
}

.moving .item-content,
.moving .item-operation {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.moving {
  width: 375px;
  height: auto;
  box-sizing: border-box;
  border-bottom: 7.5px solid #f4f4f4;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
}

ul li {
  border-bottom: 6px solid #f4f4f4;
}

.address-item {
  display: flex;
  align-items: center;
}

.item {
  overflow: hidden;
  margin: 15px 0;
}
.item .address-detail {
  width: 17em;
}
.item .left {
  float: left;
}

.tip {
  font-size: 13px;
  color: #cccccc;
}
.item .right {
  float: left;
  margin-left: 20px;
  color: #999;
}

.default {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 65px;
  height: 25px;
  border-radius: 125px;
  border: solid 1px #999999;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  background-color: #999999;
  display: inline-block;
}

.item img {
  width: 6px;
  height: 95px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.submit-order-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  width: 9px;
  height: 16px;
  margin-top: -8px;
}

.submit-order-icon img {
  width: 9px;
  height: 16px;
}
</style>
