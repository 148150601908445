
import {
  defineComponent,
  ref,
  reactive,
  computed,
  PropType,
  watch,
  onMounted,
} from "vue";
import UiCheckbox from "@/components/UI/Checkbox";
import UiCheckboxGroup from "@/components/UI/CheckboxGroup";
export default defineComponent({
  name: "AddressList",
  props: {
    listAddress: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => {
        return [];
      },
    },
  },
  components: {
    UiCheckbox,
    UiCheckboxGroup,
  },
  setup(props, ctx) {
    const checked1 = ref<string[]>([]);
    const nativeCheck = computed(() => {
      return props.modelValue;
    });
    function setCheckValue() {
      checked1.value = nativeCheck.value;
    }
    watch(nativeCheck, () => {
      setCheckValue();
    });
    const changeBoxGroup = (val: any) => {
      if (checked1.value.length > 1) {
        checked1.value.shift();
      }
      ctx.emit("update:modelValue", val);
    };
    onMounted(() => {
      setCheckValue();
    });

    return {
      checked1,
      changeBoxGroup,
    };
  },
});
