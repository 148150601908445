<template>
  <main>
    <address-list :listAddress="listAddress" v-model="checked1"></address-list>
  </main>

  <footer>
    <ul class="footer-nav">
      <li class="footer-li button">
        <router-link :to="{ name: 'Address' }"> 添加新地址 </router-link>
        <a href="/M/User/AddAddress/0?returnUrl=/ShoppingCart/SubmitOrder"> </a>
      </li>
      <li class="footer-li button" style="background-color: #b9135a">
        <a @click="onSubmit" href="javascript:;" id="submit"> 提交订单 </a>
      </li>
    </ul>
  </footer>
  <loading :options="loadOption" v-if="loading"></loading>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import qs from "qs";
import router from "@/router";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import AddressList from "@/components/address/AddressList.vue";
export default defineComponent({
  name: "SubmitOrder",
  components: {
    AddressList,
    Loading,
  },
  setup() {
    const listAddress = ref<[] | null>(null);
    const checked1 = ref<string[]>([]);
    const loading = ref(true);

    axios
      .get("/M/ShoppingCart/SubmitOrder", {
        params: {
          validate: true,
        },
      })
      .then((res) => {
        if (res.data.success) {
          listAddress.value = res.data.obj.listAddress;
          listAddress.value?.forEach((item: any) => {
            if (item.isDefault) {
              checked1.value.push(item.id);
            }
          });
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
        loading.value = false;
      })
      .catch((err) => {
        Toast({
          type: "error",
          title: err.message,
        });
        loading.value = false;
      });
    function onSubmit() {
      if (!checked1.value.length) {
        Toast({
          type: "warning",
          title: "请选择收货地址",
        });
        return false;
      }
      axios
        .post(
          "/M/Server/DoCheckout",
          qs.stringify({
            addressid: checked1.value[0],
          })
        )
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("showRedPackage", "true");
            Modal({
              title: "提示",
              content: "下单成功！客服人员将联系您确认订单。",
              closeText: "关闭",
              onClose: () => {
                router.push({
                  name: "Order",
                  query: {
                    type: 1,
                  },
                });
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
        });
    }
    
    const loadOption = {
      text: "加载中...",
      color: "#b9135a",
      textColor: "#b9135a",
    };

  

    return {
      listAddress,
      checked1,
      onSubmit,
      loadOption,
      loading,
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 109.987px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 49.987px;
  border-top: 1.012px solid #f4f4f4;
  font-size: 13.988px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 13.988px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 19.5px;
  height: 19.5px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #b9135a;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 10px;
}
footer .button a {
  color: #fff;
}

.footer-nav {
  padding-left: 34%;
  box-sizing: border-box;
}
</style>