
import { defineComponent, ref, reactive } from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import qs from "qs";
import router from "@/router";
import Loading from "@/components/UI/Loading/src/Loading.vue";
import AddressList from "@/components/address/AddressList.vue";
export default defineComponent({
  name: "SubmitOrder",
  components: {
    AddressList,
    Loading,
  },
  setup() {
    const listAddress = ref<[] | null>(null);
    const checked1 = ref<string[]>([]);
    const loading = ref(true);

    axios
      .get("/M/ShoppingCart/SubmitOrder", {
        params: {
          validate: true,
        },
      })
      .then((res) => {
        if (res.data.success) {
          listAddress.value = res.data.obj.listAddress;
          listAddress.value?.forEach((item: any) => {
            if (item.isDefault) {
              checked1.value.push(item.id);
            }
          });
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
        loading.value = false;
      })
      .catch((err) => {
        Toast({
          type: "error",
          title: err.message,
        });
        loading.value = false;
      });
    function onSubmit() {
      if (!checked1.value.length) {
        Toast({
          type: "warning",
          title: "请选择收货地址",
        });
        return false;
      }
      axios
        .post(
          "/M/Server/DoCheckout",
          qs.stringify({
            addressid: checked1.value[0],
          })
        )
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("showRedPackage", "true");
            Modal({
              title: "提示",
              content: "下单成功！客服人员将联系您确认订单。",
              closeText: "关闭",
              onClose: () => {
                router.push({
                  name: "Order",
                  query: {
                    type: 1,
                  },
                });
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.message,
          });
        });
    }
    
    const loadOption = {
      text: "加载中...",
      color: "#b9135a",
      textColor: "#b9135a",
    };

  

    return {
      listAddress,
      checked1,
      onSubmit,
      loadOption,
      loading,
    };
  },
});
